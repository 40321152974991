import React from 'react';
import { FaBlog, FaTwitter, FaGithub, FaYoutube, FaMedium } from 'react-icons/fa';
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinkItems,
    FooterLinksWrapper,
    FooterLinkTitle,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    SocialIcons,
    WebsiteRights,
    SocialIconLink
} from './FooterElements';
import { animateScroll as scroll } from 'react-scroll';

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle> About us </FooterLinkTitle>
                            <a href="https://www.dreamfinity.io/"style={{ color: '#FFD700' }}> Dreamfinity LLC. </a>
                            <a href="https://dreamfinity.gitbook.io/pug-life-by-dreamfinity/overview/dreamfinity/team"style={{ color: '#FFD700' }}> Team </a>
                            <a href="https://dreamfinity.gitbook.io/pug-life-by-dreamfinity/fundamentals/puglife-terms-of-service"style={{ color: '#FFD700' }}> Terms of Service </a>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle> Contact us </FooterLinkTitle>
                            <a href="https://www.discord.gg/puglife"style={{ color: '#FFD700' }}> Discord </a>
                            <a href="https://t.me/+UiZzaCzPaqU0YTFi"style={{ color: '#FFD700' }}> Telegram </a>
                            <a href="mailto:puglifegame@gmail.com"style={{ color: '#FFD700' }}> E-Mail </a>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle> FAQ </FooterLinkTitle>
                            <a href="https://www.youtube.com/watch?v=4f7GUHL6zCQ"style={{ color: '#FFD700' }}> How To Play </a>
                            <a href="https://www.youtube.com/watch?v=4f7GUHL6zCQ"style={{ color: '#FFD700' }}> Videos </a>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle> Social Media </FooterLinkTitle>
                            <a href="https://twitter.com/puglifegame"style={{ color: '#FFD700' }}> Twitter </a>
                            <a href="https://dreamfinity.gitbook.io/pug-life-by-dreamfinity"style={{ color: '#FFD700' }}> Gitbook </a>
                            <a href="https://www.youtube.com/channel/UCeUUKx3_T_aMlqWb2b0q3OA"style={{ color: '#FFD700' }}> Youtube </a>
                            <a href="https://www.discord.gg/puglife" style={{ color: '#FFD700' }}> Discord </a>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            PugLife
                        </SocialLogo>
                        <WebsiteRights> PugLife © {new Date().getFullYear()} All rights reserved. </WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="https://medium.com/@puglifegame" target="_blank" aria-label="Medium">
                                <FaMedium />
                            </SocialIconLink>
                            <SocialIconLink href="https://mirror.xyz/0xC123393D1942d5Dc363C47533C1C7240723A55aE/1kiwetvKgJWHJhbW6lJNEkWwENZYCNY4atv7Ry-PgWQ" target="_blank" aria-label="Mirror.XYZ">
                                <FaBlog />
                            </SocialIconLink>
                            <SocialIconLink href="https://www.youtube.com/channel/UCeUUKx3_T_aMlqWb2b0q3OA" target="_blank" aria-label="Youtube">
                                <FaYoutube />
                            </SocialIconLink>
                            <SocialIconLink href="https://twitter.com/puglifegame" target="_blank" aria-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>
                            <SocialIconLink href="https://dreamfinity.gitbook.io/pug-life-by-dreamfinity" target="_blank" aria-label="GitBook">
                                <FaGithub />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    );
};

export default Footer;
