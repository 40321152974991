// undraw.co to get svg images

export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    lightTop: true,
    topLine: 'PugLife: presented by Dreamfinity',
    headline: 'Join our virtual pet role-playing adventure today!',
    description: 'Welcome to the vibrant world of PugLife, a brand-new pet role-playing game that seamlessly blends the joy of nurturing virtual pets with the cutting-edge technology of cryptocurrency and blockchain. Designed for both mobile and desktop users, PugLife offers an engaging and immersive experience for casual mobile gamers, cryptocurrency enthusiasts, and tech-savvy individuals alike.',
    buttonLabel: 'Scroll back to the top',
    imgStart: false,
    img: require('../../images/logo.png'),
    alt: 'PugLife',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Immerse yourself in the world of PugLife today with our first mini-game!',
    headline: 'PugRun: The Quest for Bones',
    description: 'Welcome to the adventurous world of PugRun - The Quest for Bones, our first mini-game within the PugLife universe! Excel at this fun & engaging Jump & Run mini-game, compete with our community and your friends and conquer the leaderboard for your shot at amazing prizes each season!',
    buttonLabel: 'Play PugRun!',
    imgStart: true,
    img: require('../../images/pugrunlogonew.png'),
    alt: 'PugRun',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine:"In the vibrant world of PugLife, one figure stands out like a beacon of relaxation amidst the whirlwind of competitive events and daily care duties: Pudge, the iconic vacation pug. Sporting a woven straw hat perched casually atop his head and oversized sunglasses that hint at a devil-may-care attitude, Pudge embodies the spirit of leisure. Legend has it that Pudge was once a fierce competitor in the agility challenges and beauty contests, known for his speed and charm.",
    description: "But after achieving legendary status with an unrivaled streak of victories, he chose to swap the racecourse for a peaceful beach chair. Under the shade of a gently swaying palm tree, Pudge now sips on tropical drinks while basking in the glow of the setting sun, content with his legacy. His laid-back demeanor is an inspiration to many, reminding Pug caretakers and players alike of the importance of finding joy beyond the grind. He encourages them to explore the interactive elements, like mini-games and trading opportunities, to unwind from the rigors of training and competitions. Despite his leisurely lifestyle, Pudge keeps a careful eye on the community, offering guidance to budding breeders and those hoping to climb the ranks. From time to time, he shares his secrets for grooming and raising the perfect pug, revealing tips on nutrition, training, and managing social relationships. As the world of PugLife continues to grow, Pudge’s legacy is a testament to the game's core message: Life isn't always about winning the next competition; it's about enjoying each moment, whether it's the thrill of victory or the tranquility of a sunset on the beach.",
    buttonLabel: 'Scroll back to the top',
    buttonLabel: '$PUDGE CA: 0x9Aec706cf873f8B944a970cd5bf2430f8bC344C9',
    imgStart: false,
    img: require('../../images/pudge.png'),
    alt: 'Pudge',
    dark: false,
    primary: false,
    darkText: true
}
export const homeObjFour = {
    id: 'roadmap',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    lightTop: true,
    topLine: 'WE WILL BE RETURNING 100% OF BLAST POINTS AND BLAST GOLD RECEIVED BACK TO OUR PLAYERS',
    headline: 'Adopt (mint) a Pug, level it up, boost its stats with items, and compete to see if yours becomes the ultimate champion!',
    description: "Our PugLife game is brought to you as a fully on-chain web3 gaming experience powered by the Blast L2 blockchain.",
    buttonLabel: 'Scroll back to the top',
    imgStart: false,
    img: require('../../images/pugliferoadmap.png'),
    alt: 'BlastL2',
    dark: true,
    primary: true,
    darkText: false
}
