import styled from 'styled-components'
import { Button } from '../ButtonElement'

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#000')};
    padding: 100px 0;

    @media screen and (max-width: 768px) {
        padding: 50px 0;
    }
`

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 100%;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        padding: 0 24px;
    }
`

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1' 'col2'` : `'col1' 'col2'`)};
    }
`

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`

export const TopLine = styled.p`
    color: ${({ lightTop }) => (lightTop ? '#FFD700' : '#25a5db')};
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
`

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 35px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? '#FFD700' : '#010606')};

    @media screen and (max-width: 480px) {
        font-size: 24px;
    }
`

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 14px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
`

export const BtnWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media screen and (max-width: 768px) {
        margin-top: 35px;
    }
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;

    @media screen and (max-width: 768px) {
        margin-top: -82px;
    }
`

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;

    @media screen and (max-width: 768px) {
        margin-top: 25px;
        width: 80%;
    }
`

export const CAButton = styled(Button)`
    margin-top: 20px;

    @media screen and (max-width: 768px) {
        font-size: 12px;
        padding: 8px 16px;
        margin-top: 10px;  /* Add some margin to differentiate it from the other button */
    }

    @media screen and (max-width: 480px) {
        font-size: 6px;
        padding: 4px 10px;
        margin-top: 10px;  /* Add some margin to differentiate it from the other button */
    }
`
