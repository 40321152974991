import React from 'react'
import { 
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img,
    CAButton
} from './InfoElements'
import { Button } from '../ButtonElement'

const InfoSection = ({ 
    id, 
    lightBg,
    lightTop,
    imgStart, 
    topLine, 
    lightText, 
    headline, 
    darkText, 
    description, 
    buttonLabel, 
    img, 
    alt,
    primary,
    dark,
    dark2
}) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const handleClick = () => {
        window.open("https://runner.puglife.io", "_blank");
    }

    const handleClickSignup = () => {
        window.open("https://blasterswap.com/swap?address0=0x4300000000000000000000000000000000000004&address1=0x9Aec706cf873f8B944a970cd5bf2430f8bC344C9", "_blank");
    }

    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine lightTop={lightTop}> {topLine} </TopLine>
                                <Heading lightText={lightText}> {headline} </Heading>
                                <Subtitle darkText={darkText}> {description} </Subtitle>
                                {/* Conditionally render the buttons */}
                                {id === 'discover' ? (
                                    <BtnWrap> 
                                        <Button 
                                            onClick={handleClick}
                                            primary={primary ? 1 : 0}
                                            dark={dark ? 1 : 0}
                                            dark2={dark2 ? 1 : 0}
                                        >
                                            {buttonLabel}
                                        </Button>
                                    </BtnWrap>
                                ) : id === 'signup' ? (
                                    <BtnWrap> 
                                        <Button 
                                            onClick={handleClickSignup}
                                            primary={primary ? 1 : 0}
                                            dark={dark ? 1 : 0}
                                            dark2={dark2 ? 1 : 0}
                                        >
                                            Buy $PUDGE here!
                                        </Button>
                                        <CAButton 
                                            primary={primary ? 1 : 0}
                                            dark={dark ? 1 : 0}
                                            dark2={dark2 ? 1 : 0}
                                        >
                                            $PUDGE CA: 0x9Aec706cf873f8B944a970cd5bf2430f8bC344C9
                                        </CAButton>
                                    </BtnWrap>
                                ) : (
                                    <BtnWrap> 
                                        <Button 
                                            onClick={scrollToTop}
                                            primary={primary ? 1 : 0}
                                            dark={dark ? 1 : 0}
                                            dark2={dark2 ? 1 : 0}
                                        >
                                            {buttonLabel}
                                        </Button>
                                    </BtnWrap>
                                )}
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt}/>
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection
