import React from 'react'
import Icon1 from '../../images/genesispug1.png'
import Icon2 from '../../images/genesispug2.png'
import Icon3 from '../../images/genesispug4.png'
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements'

const Services = () => {
  return (
    <ServicesContainer id='services'>
        <ServicesH1> Meet the Genesis Pugs! </ServicesH1>
        <ServicesWrapper>
            <ServicesCard>
              <ServicesIcon src={Icon1}/>
              <ServicesH2> Genesis & BlastPugs </ServicesH2>
              <ServicesP> The only breedable breed. </ServicesP>
            </ServicesCard>
            <ServicesCard>
              <ServicesIcon src={Icon2}/>
              <ServicesH2> In a world full of $PUG </ServicesH2>
              <ServicesP> Everyone tries to catch some $BONES. </ServicesP>
            </ServicesCard>
            <ServicesCard>
              <ServicesIcon src={Icon3}/>
              <ServicesH2> Pugs full of skills </ServicesH2>
              <ServicesP> Stamina, Agility, Strength, IQ. </ServicesP>
            </ServicesCard>
        </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services