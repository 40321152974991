import React, { useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import {
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavBtn,
    NavBtnLink
} from './NavbarElements';

// Import your logo image
import logoImage from '../../images/blast2.png';

const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);

        return () => {
            window.removeEventListener('scroll', changeNav);
        };
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <>
            <IconContext.Provider value={{ color: '#FFD700' }}>
                <Nav scrollNav={scrollNav}>
                    <NavbarContainer>
                        {/* Logo with text */}
                        <NavLogo to="/" onClick={toggleHome}>
                            <img src={logoImage} alt="Logo" style={{ marginRight: '10px', height: '40px' }} />
                            PugLife
                        </NavLogo>

                        {/* Mobile menu icon */}
                        <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon>

                        {/* Navigation menu */}
                        <NavMenu>
                            <NavItem>
                                <NavLinks to="about" smooth={true} duration={500} spy={true} activeClass='active' exact='true'> PugLife </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="discover" smooth={true} duration={500} spy={true} activeClass='active' exact='true'> PugRun </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="services" smooth={true} duration={500} spy={true} activeClass='active' exact='true'> Genesis Pugs </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="signup" smooth={true} duration={500} spy={true} activeClass='active' exact='true'> Pudge </NavLinks>
                            </NavItem>
                        </NavMenu>

                        {/* Navigation button */}
                        <NavBtn>
                            <NavBtnLink href="https://play.puglife.io" target="_blank" rel="noopener noreferrer">Play PugLife!</NavBtnLink>
                        </NavBtn>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    );
};

export default Navbar;
