import React from 'react'
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SideBtnWrap,
    SidebarRoute,
    SidebarExternalLink
} from './SidebarElements'

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon/>
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="about" onClick={toggle}> PugLife </SidebarLink>
                <SidebarLink to="discover" onClick={toggle}> PugRun </SidebarLink>
                <SidebarLink to="services" onClick={toggle}> Genesis Pugs </SidebarLink>
                <SidebarLink to="signup" onClick={toggle}> Pudge </SidebarLink>
                <SidebarExternalLink href="https://play.puglife.io" onClick={toggle}> Play PugLife! </SidebarExternalLink>
            </SidebarMenu>
            <SideBtnWrap>
            </SideBtnWrap>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar